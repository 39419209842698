import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Mission } from "../model/Mission";
import { ClientContact } from "../model/ClientContact";

@Injectable({
  providedIn: "root",
})
export class MissionService {
  baseUrl: string = "/mission-ms/api/v1/missions";

  constructor(private http: HttpClient) {}

  getAll(): Observable<any> {
    return this.http.get(this.baseUrl);
  }
  
  getMissionById(missionId: bigint): Observable<Mission>{
    return this.http.get<Mission>(`${this.baseUrl}/${missionId}`)
  }

  getMissionStatsByConsultantId(id): Observable<any> {
    return this.http.get(this.baseUrl +"/consultants/stats/" + id);
  }

  getMissionStatsByManagerId(id): Observable<any> {
    return this.http.get(this.baseUrl + "/manager/stats/" + id);
  }

  getMissionsByManagerId(id): Observable<Mission[]> {
    return this.http.get<Mission[]>(this.baseUrl + "/by_manager/" + id);
  }

  getProjects(): Observable<any> {
    return this.http.get(this.baseUrl + "projects");
  }
  
  getMissionsByConsultantId(id): Observable<Mission[]> {
    return this.http.get <Mission[]>(this.baseUrl + "/consultant/"+id);
  }

  createMission(mission: Mission,language:string): Observable<Mission> {
    const requestParam = `?language=${language}`
    return this.http.post<Mission>(`${this.baseUrl}?language=${language}`, mission);
  }

  RemoveMissionById(id) {
    return this.http.delete(`${this.baseUrl}/${id}`);
  }

  RemoveMissionByHrId(id) {
    return this.http.delete(this.baseUrl + "missions/by-hr/" + id);
  }

  updateMission(mission: any): Observable<Mission> {
    return this.http.put<Mission>(this.baseUrl,mission);
  }
  
  getMissionByStatusCode(code: string): Observable<Mission[]>{
    return this.http.get<Mission[]>(`${this.baseUrl}/status/${code}`)
  }

  getMissionStatsByStatus():Observable<Map<string,Number>> {
    return this.http.get<Map<string, Number>>(`${this.baseUrl}/status/stats`);
  }

  getMissionStatsByClient(companyEntityId:any): Observable<Map<string, any>> {
    return this.http.get<Map<string, any>>(`${this.baseUrl}/client/stats/${companyEntityId}`);
  }

  getMissionByCompanyEntityId(companyEntityId: any): Observable<Mission[]>{
    return this.http.get<Mission[]>(`${this.baseUrl}/by_company/${companyEntityId}`)
  }

  suspendMission(missionId: bigint): Observable<Mission>{
    return this.http.put<Mission>(`${this.baseUrl}/suspended/${missionId}`, {})
  }

  getMissionsByHrID(hrId: BigInt): Observable<Mission[]>{
    return this.http.get<Mission[]>(`${this.baseUrl}/consultant_hr/${hrId}`)
  }
  
  getMissionByClientId(clientId: bigint): Observable<Mission[]>{
    return this.http.get<Mission[]>(`${this.baseUrl}/client/${clientId}`)
  }

  updateMissionContacts(missionId:bigint,clientContacts:ClientContact[]){
    return this.http.put(`${this.baseUrl}/client_contacts/${missionId}`,clientContacts)
  }

  getMissionsByClientContactEmail(clientContactEmail){
    const requestParam = `?email=${clientContactEmail}`
    return this.http.get(`${this.baseUrl}/client_contact/by_email${requestParam}`)
  }

  getLatestMissionByHrId(hrId:bigint): Observable<any>{
    return this.http.get(`${this.baseUrl}/latest/consultant_hr/${hrId}`)
  }
  getAllMissionsByConsultantId(consultantId): Observable<Mission[]> {
    return this.http.get <Mission[]>(`${this.baseUrl}/by_consultant/${consultantId}`);
  }
}
